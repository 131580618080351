import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/components/home/home.vue";
import AdminLayout from "@/components/layout/admin.vue";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "homeDetail",
  //   component: HomeView,
  //   children: [
  //     {
  //       path: "/home/product",
  //       component: () => import("@/views/home/home-product.vue"),
  //       name: "HomeProduct",
  //       meta: { title: "产品介绍", icon: "dashboard", affix: true },
  //     },
  //   ],
  // },
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        component: () => import("@/views/home/home-main.vue"),
        name: "HomeMain",
        meta: { title: "首页", icon: "dashboard", affix: true },
      },
      {
        path: "/home/product",
        component: () => import("@/views/home/home-product.vue"),
        name: "HomeProduct",
        meta: { title: "产品介绍", icon: "dashboard", affix: true },
      },
      {
        path: "/home/longhair",
        component: () => import("@/views/home/home-longhair.vue"),
        name: "HomeLonghair",
        meta: { title: "艺术家", icon: "dashboard", affix: true },
      },
      {
        path: "/home/activity",
        component: () => import("@/views/home/home-activity.vue"),
        name: "HomeActivity",
        meta: { title: "参与活动", icon: "dashboard", affix: true },
      },
      {
        path: "/home/intro",
        component: () => import("@/views/home/home-intro.vue"),
        name: "HomeIntro",
        meta: { title: "公司介绍", icon: "dashboard", affix: true },
      },
    ],
  },
  {
    path: "/admin",
    component: AdminLayout,
    name: 'admin',
    redirect: "activity",
    children: [
      {
        path: "/admin/activity",
        component: () => import("@/views/admin/activity.vue"),
        name: "AdminIndex",
        meta: { title: "活动管理", icon: "dashboard", affix: true },
      },
      {
        path: "/admin/company",
        component: () => import("@/views/admin/company.vue"),
        name: "AdminCompany",
        meta: { title: "公司信息", icon: "dashboard", affix: true },
      },
      {
        path: "/admin/product",
        component: () => import("@/views/admin/product.vue"),
        name: "AdminProduct",
        meta: { title: "产品管理", icon: "dashboard", affix: true },
      },
      {
        path: "/admin/article",
        component: () => import("@/views/admin/article.vue"),
        name: "AdminArticle",
        meta: { title: "文章管理", icon: "dashboard", affix: true },
      },
      {
        path: "/admin/artist",
        component: () => import("@/views/admin/artist.vue"),
        name: "AdminArtist",
        meta: { title: "艺术家管理", icon: "dashboard", affix: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
