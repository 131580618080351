<template>
  <div class="wrap">
    <div class="page">
      <div class="logo">
        <img style="width: 500px;" src="@/assets/logo.png" />
      </div>
      <div class="page-main">
        <home-meun/>
      </div>
      <div class="page-footer">
        <el-row :gutter="100">
          <el-col :span="6">
            <div class="footer-item">
              <div class="item-title">
                联系我们
              </div>
              <div class="item-info">
                <el-icon color="#fff"><User /></el-icon>
                {{companyData.contacts}}
              </div>
              <div class="item-info">
                <el-icon><Phone /></el-icon>
                {{companyData.tel}}
              </div>
              <div class="item-info">
                <el-icon><Message /></el-icon>
                {{companyData.mailbox}}
              </div>
              <div class="item-info">
                <el-icon><LocationInformation /></el-icon>
                {{companyData.address}}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="footer-item">
              <div class="item-title">售后服务</div>
              <div class="item-text">
                {{companyData.afterSalesContent}}
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="footer-item">
              <div class="item-title">添加微信</div>
              <div class="item-image">
                <el-image 
                style="width: 120px; height: 120px" 
                :src="apiUrl+ qrCodeUrl" 
                fit="fill" />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="chatPop">
      <div class="hideChat" @click="chatHandle" v-if="!chatFlag">
        联系我们
        <br>
        <el-icon color="#0FFF00"><ChatRound /></el-icon>
      </div>
      <div class="showChat" @click="chatHandle" v-if="chatFlag">
        <el-image style="width: 120px; height: 120px" :src="apiUrl+ qrCodeUrl" fit="fill" />
      </div>
    </div>

  </div>
</template>

<script setup name="App">
  
  import { ref,onMounted } from "vue";
  import request from '../../utils/request'
  import { User,Phone,Message,LocationInformation,ChatRound } from '@element-plus/icons-vue'
  import HomeMeun from './home-meun.vue'  //  首页

  const activeName = ref('first')
  const homeMainRef = ref(null)
  const homeLonghairRef = ref(null)
  const homeActivityRef = ref(null)
  const chatFlag = ref(false)
  const apiUrl = process.env.VUE_APP_BASE_API_URL
  const qrCodeUrl = ref('')
  const companyData = ref({})
  onMounted(()=>{
    const urlPah = require('../../assets/bg.png')
    document.querySelector('body')
      .setAttribute('style', 'background-repeat:no-repeat;background-size:100% 100%; background-image:url("' + urlPah + '") ')
    document.querySelector('html')
      .setAttribute('style', 'background:#000;')
      getList()
  })
  function getList() {
      request.get('/Company/company').then((res)=>{
        if (res.data.length>0) {
          companyData.value = res.data[0]
          qrCodeUrl.value = Object.values(companyData.value.wxFileInfo)[0]
        }
      })
  }
  function chatHandle() {
    chatFlag.value = !chatFlag.value
  }
  const handleClick = (tab) => {
    if (tab.props.name === 'first') {
      homeMainRef.value.detailFlag = false
    } else if (tab.props.name === 'third') {
      homeLonghairRef.value.detailFlag = false
    } else if (tab.props.name === 'fourth') {
      homeActivityRef.value.detailFlag = false
    }
  }
</script>

<style lang="scss" scoped>
  
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #9d9d9d;
    margin-top: 60px;

  }
  :deep(.root){
    --el-color-primary: #000;  
  }
  :deep(.el-tabs__nav-wrap:after){
    height: 0px !important;
  }
  :deep(.el-tabs__nav) {
    float: right;
    .el-tabs__item{
      font-size: 22px;
      color: #9d9d9d;
    }
    .el-tabs__item.is-active{
      color: #fff;
    }
  }
  .title{
    line-height: 50px;
    font-size: 26px;
    font-weight: bold;
  }
  .content{
    font-size: 16px;
    line-height: 30px;
  }
  .wrap {
    .chatPop{
      cursor: pointer;
      margin-top: -70px;
      position: fixed;
      top: 50%;
      right: 0;
      text-align: center;
      background: #636363;
      .hideChat{
        width: 30px;
        word-wrap: break-word;/*英文的时候需要加上这句，自动换行*/
        word-break:break-all;
        border-radius: 5px 0px 0px 5px;
        line-height: 26px;
        padding: 10px;
        color: #fff;
      }
     
    }
    .page {
      width: 80%;
      height: 100%;
      margin: 0 auto;

      position: relative;
      .logo{
        position: absolute;
        left: -120px;
        top: -20px;
        z-index: 1;
      }
      .page-main{
        /* height: 550px; */
        
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-x: hidden;
        overflow-y: hidden;
      }
      .page-footer{
        text-align: left;
        border-top: 2px solid #2c3e50;
        padding:15px;
        margin-top: 80px;
        .footer-item{
          .item-title{
            color: #D0D0D0;
            display: inline-block;
            font-size: 18px;
            line-height: 50px;
            border-bottom: 4px solid;
            border-image: linear-gradient(to right, #FE8700, #80490B) 1;
          }
          .item-info{
            line-height: 36px;
            color: #D0D0D0;
          }
          .item-text{
            margin-top: 15px;
            color: #9d9d9d;
            line-height: 36px;
            text-indent: 2em;
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:4;
          }
          .item-image{
            margin-top: 30px;
          }
        }
      }
    }
    
  }
</style>