<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <div class="flex-grow"></div>
          <template v-for="(item, index) in menus"  v-bind:key="index">
            <el-menu-item  v-if="item.meta.title !== '产品介绍'" :index="index">{{ item.meta.title }}</el-menu-item>
            <el-sub-menu v-else :index="index">
              <template #title>产品介绍</template>
              <el-menu-item v-for="(item, index) in productData" v-bind:key="index" index="1" @click="trunHandle(item.id)">{{item.name}}</el-menu-item>
            </el-sub-menu>
          </template>
        </el-menu>
      </el-header>
      <el-main>
        <el-scrollbar style="margin-top: 20px;">
          <router-view />
        </el-scrollbar>
      </el-main>
    </el-container>
  </div>
</template>
<script setup>
import { ref,onMounted } from 'vue'
import request from '../../utils/request'
import routers from "../../router";
import { useRouter } from "vue-router";
import { ArrowDown } from '@element-plus/icons-vue'

const apiUrl = process.env.VUE_APP_BASE_API_URL 
const productData = ref([])
const menus = routers.options.routes.find((o) => { return o.name === 'home'; }).children;
const router = useRouter();
const index = menus.findIndex((o) => { return o.path === router.currentRoute.value.path });
const activeIndex = ref(index)
onMounted(()=>{
    getList()
})
function getList() {
    request.get('/Product/product').then((res)=>{
        res.data.length>0 ? (productData.value = res.data) : (productData.value = [])
    })
}
const handleSelect = (key, keyPath) => {
  console.log(key, keyPath)
  activeIndex.value = Number(key);
  if (Number(key)!==1) {
    router.push(menus[key])
  }
}
function trunHandle(id) {
  router.push({ name: 'HomeProduct', query: { id }})
}
</script>
<style lang="scss" scoped>
  :deep(.el-menu ){
    background:none;
    border: none !important;

  }
  :deep(.el-menu--horizontal>.el-menu-item.is-active ) {
    color: #fff !important;
      border: none;
  }
  :deep(.el-menu-item:not(.is-disabled):hover){
    background: none !important;
  }
  :deep(.el-menu-item:not(.is-disabled):focus){
    background-color:rgba(220,38,38,0) !important;
  }
  :deep(.el-sub-menu__title){
    font-size: 26px;
    font-weight: bold;
  }
  :deep(.el-sub-menu__title:hover){
    background: none !important;
  }
  :deep(.el-menu-item){
    font-size: 26px;
    font-weight: bold;
  }
  :deep(.el-dropdown-link){
    font-size: 22px;
    color: inherit;
  }
  .flex-grow {
    flex-grow:0.9;
  }
</style>