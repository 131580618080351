<template>
  <div class="common-layout" style="padding: 20px 100px;">
    <el-container>
      <el-header>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item v-bind:key="index" :index="index" v-for="(item, index) in menus">{{ item.meta.title }}</el-menu-item>
          <!-- <el-sub-menu index="2">
            <template #title>Workspace</template>
            <el-menu-item index="2-1">item one</el-menu-item>
            <el-menu-item index="2-2">item two</el-menu-item>
            <el-menu-item index="2-3">item three</el-menu-item>
            <el-sub-menu index="2-4">
              <template #title>item four</template>
              <el-menu-item index="2-4-1">item one</el-menu-item>
              <el-menu-item index="2-4-2">item two</el-menu-item>
              <el-menu-item index="2-4-3">item three</el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
          <el-menu-item index="3" disabled>Info</el-menu-item>
          <el-menu-item index="4">Orders</el-menu-item> -->
        </el-menu>
      </el-header>
      <el-main style="padding: 20px;">
        <!-- <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">homepage</el-breadcrumb-item>
          <el-breadcrumb-item><a href="/">promotion management</a></el-breadcrumb-item>
          <el-breadcrumb-item>promotion list</el-breadcrumb-item>
          <el-breadcrumb-item>promotion detail</el-breadcrumb-item>
        </el-breadcrumb> -->
        <el-scrollbar style="margin-top: 20px;">
          <router-view />
        </el-scrollbar>
      </el-main>
    </el-container>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import routers from "../../router";
import { useRouter } from "vue-router";

const menus = routers.options.routes.find((o) => { return o.name === 'admin'; }).children;



const router = useRouter();
console.log(router.currentRoute.value.path, 'router');
const index = menus.findIndex((o) => { return o.path === router.currentRoute.value.path });
const activeIndex = ref(index)
const handleSelect = (key: string, keyPath: string[]) => {
  console.log(key, keyPath)

  activeIndex.value = Number(key);
  router.push(menus[key])
}
</script>